.banner_send {
    padding: 0;
    margin: 0;
    max-width: 100%;
    a {
        width: 145px;
        height: auto;
        display: block;
        margin: 0 auto;
        text-align: center;
        position: relative;
        left: -12px;
        img {
            max-width: 100%;
        }
    }
}
