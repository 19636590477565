.container {
  max-width: 100%;
}

main > .container {
  width: 100%;
}

.row.home,
.row.tracking-container {
  position: relative;
  background-color: #fff;
  margin-bottom: 15px;
  box-shadow: 0 10px 5px -4px #bfc0c2;
}

body {
  background-color: $bg-body;
  color: #333;
  min-width: 320px;
  font-family: $font-ptsacscaption;
}

section {
  background-color: #fff;
  margin-bottom: 20px;
}

a,
body,
label,
p {
  font-size: 12px;
  line-height: 1;
}

header,
main {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
  padding: 0 10px;
}

footer {
  padding: 0;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.prim-color {
  color: $blue;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

header,
main {
  padding: 0;
}

/*
1- Delegación destino (aceptado)
2- Entregado (NO aceptado)
3- Reparto fallido: (aceptado)
4- Nuevo reparto (NO aceptado)
5- En reparto (aceptado)
6 Sin recepción (aceptado)
*/

.button-red {
  font-size: 16px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 17px;
  font-family: 'ptsanscaption-regular',Arial,Helvetica,sans-serif;
  color: #fff;
  padding: 4px 23px 4px 48px;
  height: 28px;
  font-weight: bold;
  background-color: $red;
  background-image: url("../images/flecha-btn-continuar.png");
  background-repeat: no-repeat;
  background-position: 18px 3px;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: #fff;
    color: $red;
    border: 1px solid $blue;
    background: url("../images/flechas_rojas.png") no-repeat 20px 6px;
  }
}

h2 {
  font-family: 'ptsanscaption-bold';
  color: $blue;
  font-size: 20px;
  padding: 15px;
  margin: 0;
  font-weight: bold;
  margin-bottom: 20px;

  &.decorator {
    $side: 13px;
    position: relative;
    border-bottom: 2px solid $blue;
    z-index: 1;
    margin-left: -15px;
    margin-right: -15px;

    &:after,
    &:before {
      content: " ";
      position: absolute;
      left: 30px;
      border-top: $side solid #fff;
      border-left: $side solid transparent;
      border-right: $side solid transparent;
    }

    &:before {
      bottom: -($side);
      border-top: $side solid $blue;
    }

    &:after {
      bottom: -($side)+3;
    }
  }
}

.form-control-lg {
  padding: 0 12px;
  font-size: 18px;
}

.input-group .form-control {
  height: 45px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-t-20 {
  margin-top: 20px;
}
@media (min-width: $screen-sm) {
  h2.decorator {
    margin: auto auto 25px;
  }
}
/*
 * Responsive text aligning
 * http://ohryan.ca/2014/08/14/set-responsive-text-alignment-bootstrap-3/
 */
.text-xs-left {
  text-align: left;
}

.text-xs-right {
  text-align: right;
}

.text-xs-center {
  text-align: center;
}

.text-xs-justify {
  text-align: justify;
}
@media (min-width: $screen-sm) {
  .text-sm-left {
    text-align: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }

  .text-sm-justify {
    text-align: justify;
  }
}
@media (min-width: $screen-md) {
  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }

  .text-md-justify {
    text-align: justify;
  }

  .container {
    width: 992px;
  }
}
@media (min-width: $screen-lg) {
  .text-lg-left {
    text-align: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }

  .text-lg-justify {
    text-align: justify;
  }
}
