.bold {
    font-weight: bold;
}

.tracking {
    position: relative;
    height: auto;
}

.progress--bg {
    position: relative;
    margin: auto;
    background-color: #F2F2F4;
}
.wrapper--tracking-icon {
    width: 20%;
    margin-right: 5%;
    float: left;
}
.tracking-title-text {
    padding-bottom: 20px;
}
.tracking-icon {
    display: inline;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;

    margin: 0 auto;
    max-width: 100%;
    height: auto;
}
.status {
    margin-top: 0;
    float: right;
    width: 75%;
}
.status-message {
    line-height: 18px;
    width: 100%;
    clear: both;
    padding: 15px 0;
}
.manage-shipment-botonera {
    display: block;
    margin: 0 auto;
    padding-top: 20px;
    clear: both;
}
@media (min-width: $screen-md) {
    .status-message {
        width: 75%;
        clear: right;
        padding: 0;
        float: right;
    }
    .manage-shipment-botonera {
        float: right;
    }
}

.progress--bar--circle {
    background-color: $progress-bar-circle;
    color: #fff;
    padding-top: 6px;
    text-align: center;
}

.tracking .progress--bar {
    min-height: 24px;
    background-color: $progress-bar-color;
}

.progress--bg--circle {
    border: 2px solid $progress-bar-color;
}

.estimated {
    float: right;
    font-weight: bold;
}

.destiny {
    margin-top: 225px;
}

.progress-value {
    font-weight: bold;
}

.blue {
    .progress--bar {
        background-color: $blue;
    }

    .progress--bg--circle {
        border-color: $blue;
    }

    .progress--bar--circle {
        background-color: $lightenblue;
    }
}
.red {
    .progress--bar {
        background-color: $red;
    }

    .progress--bg--circle {
        border-color: $red;
    }

    .progress--bar--circle {
        background-color: $light-red;
    }
}
.progress--bar--circle {
    position: relative;
    z-index: 10;
}

.tracking-block .progress--bg {
    height: 240px;
}

.progress--bar,
.progress--bg {
    position: relative;
    border-radius: 12px;
}

.progress--bg {
    width: 24px;
}

.progress--bar--circle,
.progress--bg--circle {
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.progress--bg--circle {
    right: 0;
}

h2.decorator.tracking-decorator {
    font-size: 22px;
}

.ie9 .miyazaki {
    max-width: 100%;

    td {
        border-bottom: 1px solid #333;
        &:after,
        &:before {
            display: none;
        }
    }

    thead {
        display: block;
    }

    a {
        display: block;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

table.table {
    th {
        background-color: $blue;
        color: #FFF;

        a {
            color: #FFF;
        }
    }
}
@media (min-width:$screen-md) {
    .tracking {
        height: auto;

        .progress--bar {
            display: block;
            height: 100%;
            width: 0;
        }

        .progress--bg {
            height: 24px;
        }

        .estimated {
            margin-top: 0;
        }

        .progress-value {
            margin-top: 0;
        }

        .destiny {
            margin-top: 0;
            float: right;
        }
    }

    .progress--bar {
        animation: width 1.5s ease;
    }

    .progress--bg {
        width: 90%;
    }

    .progress--bar--circle {
        position: relative;
        z-index: 10;
    }

    .ie9 .progress--bg--circle {
        right: 0;
    }

    .ie9 .progress-value {
        float: left;
    }

    .ie9 .miyazaki a {
        max-width: 100%;
    }

    h2.decorator {
        margin: auto auto 25px;
    }

    h2.decorator.tracking-decorator {
        font-family: 'ptsanscaption-regular';
        font-size: 30px;
        font-weight: normal;
    }
}
/* MIYAZAKI TABLE*/
table.miyazaki {
    font-size: 12px;
    margin-bottom: 25px;

    a {
        word-break: break-word;
    }

    caption {
        color: #333;
        font-weight: bold;
        font-size: 14px;
    }

    thead th {
        font-weight: bold;
    }

    table.miyazaki thead th {
        padding: 0.8rem;
    }

    tbody td {
        padding: 0.8rem;
        line-height: 120%;
        color: #444;
    }
    tbody tr {
        border-top: 1px solid #333;
    }
}
@media screen and (min-width: $screen-md) {
    table.miyazaki {
        td,
        th {
            padding: 5px;
            /*border-top: 1px solid #333;*/
            vertical-align: top;
        }

        th {
            padding-bottom: 20px;
        }
    }
}
@media screen and (max-width: $screen-md) {
    .top-info-tracking-wrapper {
        height: 240px;
    }

    .estimated {
        position: absolute;
        bottom: 10px;
    }

    .progress-value {
        float: right;
    }

    table.miyazaki thead {
        display: none;
    }

    table.miyazaki tbody td {
        display: block;
        padding: 0.6rem;
    }
    table.miyazaki tbody tr td:first-child {
        /*border-bottom: 1px solid #333;*/
        color: #333;
    }
    table.miyazaki tbody td:before {
        float: left;
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 10rem;
        height: 20px;
    }
}
/* MIYAZAKI TABLE */
