.ui-dialog {
  .modal-header {
    background-color: $blue;
    padding: 5px 15px;
    color: #fff;

    h4 {
      font-size: 13px;
    }

    .close {
      opacity: 1;
      background-image: url("../images/close.png");
      background-repeat: no-repeat;
      background-position: 3px 3px;
      height: 18px;
      width: 18px;
    }
  }

  .modal-body {
    padding-bottom: 0;

    label {
      font-size: 14px;
      font-weight: bold;
      color: $blue;
      margin-top: 9px;
      margin-left: 4px;
    }

    input {
      background-image: url("../images/input_shadow.png");
      background-repeat: no-repeat;
      border: 1px solid;
      border-color: #bfbfbf #dedede #dedede #bfbfbf;
      font: 1em Arial,Helvetica,Verdana,sans-serif;
      position: relative;
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
      margin: 5px 0;
      background-color: #f5f6f8;
      border-radius: 15px;
      box-sizing: border-box;

      &:focus {
        background: #FFC none;
        border: 1px solid #FC0;
      }
    }
  }

  .modal-footer {
    border: none;
    padding: 5px 15px 15px;

    .button-red {
      background-image: none;
      font-weight: normal;
      background: $red;
      color: #fff;
      padding: 2px 23px;
      border-radius: 17px;
      width: auto;
      height: 28px;
      margin-bottom: 25px;
      margin-left: 29px;
      font-family: ptsanscaption-regular;
      font-size: 13px;
      margin: 6px;

      &:hover {
        background: #fff;
        color: $red;
        border: 1px solid $blue;
      }
    }
  }
}
