/* Datepicker size */

@media (max-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 100%;
    min-width: 100%;
  }
}
.timepicker-picker .table-condensed > tbody > tr > td.separator,
.timepicker-picker .table-condensed > tbody > tr > td:last-child{ display: none; }
/* Datepicker minutes */
