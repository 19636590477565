header {
    background-color: #fff;
}
.header-title-logo-desktop {
    display: none;
    float: left;
    width: 390px;
    padding: 7px 0 10px;
}
.header-title-logo-mobile {
    display: block;
    width: 120px;
    margin: 0 auto;
    padding: 15px 0 10px;
}
.header-contact {
    display: none;
    float: right;
    margin-right: 70px;
    margin-top: 60px;
}
.custom-icon {
    $radius: 36px;
    border-radius: $radius;
    width: $radius;
    height: $radius;
    line-height: $radius;
    margin-left: 9px;
    text-align: center;
    display: inline-block;
    vertical-align: bottom;
    background-color: $blue;
    color: #fff;
    overflow: hidden;
    font-size: 0;
    &:before {
        float: left;
        width: 100%;
        line-height: $radius;
        color: #fff;
        font-size: 20px;
        text-indent: 0;
    }
}
.responsive-hamburguer {
    display: none;
    position: absolute;
    top: 27px;
    left: 16px;
    display: block;
    img {
        height: 16px;
    }
}
.header-nav {
    clear: both;
    position: absolute;
    top: 0;
    left: -270px;
    -webkit-transition: left 0s ease;
    -moz-transition: left 0s ease;
    transition: left 0s ease;
    z-index: 100001;
    li {
        padding: 0;
        display: block;
        border-bottom: 1px solid $bg-menu;
        list-style: none;
    }
    a,
    a:active,
    a:hover,
    a:visited {
        color: $blue;
        display: block;
        width: 100%;
        padding-left: 25px;
        text-decoration: none;
    }
    img {
        margin-right: 20px;
        width: 20px;
        height: auto;
    }
    a,
    img,
    p {
        display: inline-block;
        vertical-align: middle;
        line-height: 80px;
    }
    p {
        margin: 0;
        font-size: 13px;
        font-family: 'ptsanscaption-bold';
    }
    br {
        display: none;
    }
    .header-nav-list {
        display: block;
        background-color: #fff;
        padding: 0;
    }
}
.header-nav.open {
    left: 0;
    .header-nav-list {
        width: 270px;
        height: 100%;
        font-size: 0;
    }
}
.header-nav.open + .responsive-overlay {
    display: block;
}
.responsive-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    background-color: #000;
    filter: alpha(opacity=50);
    opacity: 0.5;
}
li.header-nav-logo {
    background-color: $bg-menu;
    img {
        width: 110px;
        height: auto;
    }
}
@media (min-width: $screen-md) {
    header {
        background-color: transparent;
    }
    .header-contact,
    .header-nav-list,
    .header-title-logo-desktop {
        display: block;
    }
    .header-title-logo-mobile,
    .responsive-hamburguer,
    li.header-nav-logo {
        display: none;
    }
    .header-nav {
        position: static;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto;
        border-bottom: 5px solid $blue;
        br {
            display: inline;
        }
        a,
        a:active,
        a:hover,
        a:visited {
            width: auto;
            position: relative;
            height: 70px;
            line-height: 55px;
            padding: 0;
            margin: 0;
        }
        li {
            position: relative;
            float: left;
            border-right: 2px solid $grey-border;
            p {
                float: left;
                padding: 25px 0 0 11px;
                margin: 0;
                font-size: 13px;
                font-family: 'ptsanscaption-regular';
                line-height: 14px;
                width: 79px;
            }
            img {
                float: left;
                margin: 0;
            }
        }
        li + li {
            width: 159px;
            img {
                padding: 18px 0 0 10px;
                width: 63px;
            }
        }
        li + li + li {
            width: 148px;
            img {
                padding: 22px 0 0 5px;
                width: 49px;
            }
        }
        li + li + li + li {
            width: 132px;
            img {
                padding: 22px 0 0 5px;
                width: 49px;
            }
        }
        li + li + li + li + li {
            width: 145px;
            img {
                padding: 20px 0 0 10px;
                width: 62px;
            }
        }
        li + li + li + li + li + li {
            width: 148px;
            img {
                padding: 21px 0 0 10px;
                width: 55px;
            }
        }
    }
    .header-nav .header-nav-list,
    .header-nav.open .header-nav-list {
        min-height: 0;
        width: 100%;
        height: 70px;
        margin-bottom: 0;
        li {
            border-bottom: 0;
            float: left;
        }
    }
    .header-nav.open + .responsive-overlay {
        display: none;
    }
}
