@font-face {
    font-family: 'ptsanscaption-bold';
    src: url('fonts/ptsanscaption_bold_macroman/PTC75F-webfont.eot');
    src: url('fonts/ptsanscaption_bold_macroman/PTC75F-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/ptsanscaption_bold_macroman/PTC75F-webfont.svg#museo700') format('svg'), url('fonts/ptsanscaption_bold_macroman/PTC75F-webfont.woff') format('woff'), url('fonts/ptsanscaption_bold_macroman/PTC75F-webfont.ttf') format('truetype');
    font-style: normal;
}
@font-face {
    font-family: 'ptsanscaption-regular';
    src: url('fonts/ptsanscaption_regular_macroman/PTC55F-webfont.eot');
    src: url('fonts/ptsanscaption_regular_macroman/PTC55F-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/ptsanscaption_regular_macroman/PTC55F-webfont.svg#museo700') format('svg'), url('fonts/ptsanscaption_regular_macroman/PTC55F-webfont.woff') format('woff'), url('fonts/ptsanscaption_regular_macroman/PTC55F-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SohoGothicProBold';
    src: url('fonts/SohoGothicPro-Bold/sohogothicpro-bold.eot');
    src: url('fonts/SohoGothicPro-Bold/sohogothicpro-bold.eot') format('embedded-opentype'), url('fonts/SohoGothicPro-Bold/sohogothicpro-bold.woff') format('woff'), url('fonts/SohoGothicPro-Bold/sohogothicpro-bold.ttf') format('truetype'), url('fonts/SohoGothicPro-Bold/sohogothicpro-bold.svg#SohoGothicProBold') format('svg');
}
@font-face {
    font-family: 'SohoGothicProMedium';
    src: url('fonts/SohoGothicPro-Medium/sohogothicpro-medium.eot');
    src: url('fonts/SohoGothicPro-Medium/sohogothicpro-medium.eot') format('embedded-opentype'), url('fonts/SohoGothicPro-Medium/sohogothicpro-medium.woff') format('woff'), url('fonts/SohoGothicPro-Medium/sohogothicpro-medium.ttf') format('truetype'), url('fonts/SohoGothicPro-Medium/sohogothicpro-medium.svg#SohoGothicProMedium') format('svg');
}
@font-face {
    font-family: 'SohoGothicProRegular';
    src: url('fonts/SohoGothicPro-Regular/sohogothicpro-regular.eot');
    src: url('fonts/SohoGothicPro-Regular/sohogothicpro-regular.eot') format('embedded-opentype'), url('fonts/SohoGothicPro-Regular/sohogothicpro-regular.woff') format('woff'), url('fonts/SohoGothicPro-Regular/sohogothicpro-regular.ttf') format('truetype'), url('fonts/SohoGothicPro-Regular/sohogothicpro-regular.svg#SohoGothicProRegular') format('svg');
}
