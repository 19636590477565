.row.home {
    background-color: transparent;
}
.form-photo-1 {
    background: $blue;
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: 0;
}
.mobileSearch {
    display: block;
    .btn {
        width: 100%;
    }
}
.desktopSearch {
    display: none;
}
.row-form-search {
    position: relative;
    float: none;
    width: 100%;
    padding: 0 0 15px;
    margin: 0;
    border-radius: 0;
    right: 0;
    height: auto;
    color: #fff;
    h1 {
        font-size: 28px;
    }
}
.home-main {
    padding: 0;
    background: $blue;
}
.aux-container {
    top: 0;
    position: relative;
    margin-top: 0;
    left: 0;
    right: 0;
    background: #F5F6F8;
    padding-top: 15px;
    padding-bottom: 15px;
}
.banner-box {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 20px;
    width: 200px;
}
.detailError {
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 20px;
}
.errorMessage-1,
.errorMessage1,
.errorMessage2 {
    text-align: center;
    margin-top: 15px;
    display: none;
    span {
        color: #fff;
    }
    img {
        margin: 0 auto 10px;
    }
}
.error-1 .errorMessage-1,
.error1 .errorMessage1,
.error2 .errorMessage2 {
    display: block;
}
.errorMessage {
    display: block;
    margin-bottom: 15px;
    color: #fff;
    line-height: 14px;
    &:first-child {
        padding-top: 13px;
    }
}
.errorMessage1 .errorMessage {
    font-weight: bold;
}
.errorMessage-1 .errorMessage:nth-child(4),
.errorMessage2 .errorMessage:nth-child(4) {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
}
.errorTel {
    font-size: 16px;
    font-weight: bold;
    display: block;
    width: 100%;
    margin-top: 0;
}
.botonera {
    margin-bottom: 25px;
    margin-top: 15px;
}
.errorMsg {
    color: #f10043;
    display: block;
    font-weight: bold;
    line-height: 125%;
}
@media (min-width: $screen-md) {
    .row.home {
        background-color: #fff;
        padding-top: 15px;
        padding-bottom: 15px;
        box-shadow: 0 10px 5px -4px #bfc0c2;
    }
    .home-banners .aux-container {
        background-color: #fff;
    }
    .form-inline.form-group {
        label {
            width: 33%;
        }
        .input-group {
            width: 64%;
        }
    }
    .form-photo-1 {
        border-radius: 0;
    }
    .desktopSearch {
        display: block;
    }
    .mobileSearch {
        display: none;
        padding-bottom: 0;
        .btn {
            width: auto;
        }
    }
    .form-photo-1 {
        border-radius: 3px;
        position: relative;
        background: transparent url("../images/Correos1.jpg") no-repeat left bottom;
        background-size: cover;
        padding: 0;
        height: 500px;
    }
    .row-form-search {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 180px;
        background-color: rgba(0,69,125,.8);
        .text-muted,
        h1,
        label {
            color: #fff;
        }
        .text-muted {
            position: relative;
            top: 15px;
        }
        h1 {
            font-size: 36px;
        }
    }
    .aux-container {
        top: -50%;
        position: absolute;
        margin-top: 50%;
        left: 0;
        right: 0;
    }
    .errorMessage1,
    .errorMessage2,
    .errorMessage-1 {
        background: $blue;
        margin-top: 40px;
        padding-top: 8px;
        padding-bottom: 0;
        .errorMessage {
            display: inline-block;
            &:first-child {
                padding-left: 40px;
            }
        }
    }
    .errorMessage2,
    .errorMessage-1  {
        padding-bottom: 20px;
        margin-top: 32px;
    }
    .errorMessage1 {
        margin-bottom: 20px;
        padding-top: 4px;
    }
    .errorMessage img {
        float: left;
    }
    .errorTel {
        font-size: 16px;
    }
    .row-form-search.error {
        height: 320px;
    }
    .banner-box {
        width: 165px;
    }
    .error {
        &.error1 {
            height: 220px;
        }
        &.error2 {
            height: 320px;
        }
    }
    .truncated-tracking .form-control-lg {
        padding-top: 0;
        padding-bottom: 0;
    }
}
