.logos-correos {
    font-size: 0;
    a {
        display: inline-block;
        vertical-align: middle;
        width: 25%;
        border: 5px solid transparent;
    }
    img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
}
.logo-grupo-correos {
    text-align: center;
    min-width: 100%;
    img {
        width: 240px;
    }
}
.footer-antelogos {
    font-family: ptsanscaption-regular,Arial,Helvetica,sans-serif;
    padding: 0 10px;
    li,
    p,
    ul {
        width: 100%;
        height: auto;
        float: none;
        margin: 10px 0;
        text-align: center;
        padding: 0;
        font-size: 16px;
    }
    ul {
        border-bottom: #a9aaac 1px solid;
    }
    a,
    p {
        font-size: 10px;
        font-weight: bold;
        color: #00457d;
    }
    li {
        list-style: none;
        &:nth-child(1) {
            display: none;
        }
    }
}
.tools-social-mobile {
    display: block;
    font-size: 0;
    margin: 5px auto 0;
    width: 105px;
    position: relative;
    top: -2px;
    left: -1px;
    a {
        max-height: 34px;
        margin-left: 5px;
        width: 29px;
        height: 28px;
        &:before {
            font-size: 16px;
            line-height: 28px;
        }
        &.fa-linkedin:before {
            font-size: 14px;
            position: relative;
            top: -1px;
            left: 1px;
        }
        &.fa-twitter {
            position: relative;
            top: -1px;
            margin-left: 5px;
            &:before {
                position: relative;
                left: 1px;
            }
        }
        &.fa-rss {
            width: 27px;
            height: 26px;
            position: relative;
            left: 2px;
            top: -2px;
            &:before {
                position: relative;
                top: -1px;
                font-size: 16px;
            }
        }
    }
}
@media (min-width:$screen-md) {
    footer {
        margin: 0 4px;
    }
    .logo-grupo-correos {
        text-align: center;
        margin-top: 10px;
        img {
            float: none;
            width: auto;
        }
    }
    .logos-correos {
        .withMargin {
            margin-right: 93px;
        }
        a {
            width: auto;
            border: 0;
        }
    }
    .logos-correos a {
        position: relative;
        &.logo-correos {
            top: -3px;
        }
        &.logo-telecom {
            top: -3px;
            left: 4px;
        }
        &.logo-chx {
            left: 8px;
            top: -3px;
        }
        &.logo-nexea {
            left: 12px;
            top: -3px;
        }
    }
    .tools-social-mobile {
        display: none;
    }
    .footer-antelogos {
        width: 100%;
        border-bottom: #a9aaac 2px solid;
        margin-bottom: 8px;
        margin-top: 17px;
        height: 40px;
        .copyright {
            float: left;
            font-size: 12px;
            color: #4e4e4f;
            margin: 16px 0 0 13px;
            width: auto;
            font-weight: normal;
        }
        ul {
            width: auto;
            border: 0;
            float: right;
            margin-bottom: 9px;
            margin-top: 0;
            position: relative;
            left: -18px;
            top: -1px;
        }
        li {
            width: auto;
            position: relative;
            display: block;
            float: left;
            border-right: 2px solid #b8b9ba;
            padding: 7px 14px 15px;
            margin: 0;
            &:first-child {
                display: block;
            }
            &:last-child {
                border: 0;
            }
        }
        a {
            font-size: 11px;
            color: #4e4e4f;
            font-weight: 400;
            display: block;
            position: relative;
            top: 6px;
        }
    }
}
