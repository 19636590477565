// Colors:
$bg-body: rgb(245, 246, 248) !default;
$bg-menu: #f5f6f8 !default;
$blue: #00457d !default;
$lightenblue: #0066B5 !default;
$red: #F10043 !default;
$dark-red: #b22e56;
$light-red: #fc366e;

$trackbarGreen: #00A832;

$grey-progress: #C7D5DC;
$custom-table-bg-accent: #D3DADD;
$grey-border: #c9cacb;

$progress-bar-color: #8de971;
$progress-bar-circle: #033601;
$progress-bar-color-blue: #008df9;
$progress-bar-circle-blue: #00457d;
$progress-height: 24px;


// Fonts:
$font-ptsacscaption-bold: 'ptsanscaption-bold', Arial, Helvetica, sans-serif;
$font-ptsacscaption: 'ptsanscaption-regular', Arial, Helvetica, sans-serif;

$font-sohogothic-bold: 'SohoGothicProBold', Arial, Helvetica, sans-serif;
$font-sohogothic-medium: 'SohoGothicProMedium', Arial, Helvetica, sans-serif;
$font-sohogothic-regular: 'SohoGothicProRegular', Arial, Helvetica, sans-serif;
