.banner_ask {
    padding: 0;
    max-width: 100%;
    margin: 0;
    .col-xs-4 {
        padding-right: 0;
    }
    .title {
        padding-left: 0;
        margin-left: 0;
        font-weight: bold;
        margin-bottom: 7px;
        color: #00457d;
    }
    .fa-at {
        font-size: 55px;
        color: #00457d;
        .ie & {
            top: 45px;
        }
    }
    a,
    p {
        line-height: 16px;
    }
    a {
        font-size: 12px;
    }
}
