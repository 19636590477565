.footer-tools {
    display: none;
}
@media (min-width:$screen-md) {
    .footer-tools {
        display: block;
    }
    .titulo-footer-tools {
        background: #fff;
        font-weight: 700;
        font-size: 12px;
        color: #00457d;
        text-transform: uppercase;
        margin: 1px 0 0;
        line-height: 18px;
    }
    .subtitulo-footer-tools {
        font-size: 12px;
        color: #00457d;
        margin: 0;
    }
    .calculador-tarifa a {
        background-color: #ebedf0;
        background-image: url("../images/flecha_calcular.png");
        background-repeat: no-repeat;
        background-position: right;
        color: #00457d;
        border-radius: 12px;
        clear: both;
        display: inline-block;
        position: relative;
        width: 173px;
        height: 30px;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 8px 15px;
        margin-top: 15px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        font-family: ptsanscaption-regular;
    }
    .footer-tools {
        .container50 {
            text-align: center;
            position: relative;
            top: -2px;
            > div {
                margin: 0 auto 20px;
                text-align: left;
            }
        }
        .calculador-tarifa {
            height: auto;
            width: 315px;
        }
        .oficinas-correos {
            height: auto;
            width: 283px;
        }
        img {
            float: left;
            margin: 0 15px 0 0;
        }
        .boton-localiza-oficinas {
            margin-top: 6px;
        }
    }
    .container50 {
        float: left;
        width: 50%;
        text-align: center;
        > div {
            margin: 0 auto;
        }
    }
    .footer-tools {
        background: url("../images/flecha_footer.png") no-repeat left top #fff;
        padding-top: 41px;
        padding-bottom: 0;
        box-shadow: 0 10px 5px -4px #bfc0c2;
    }
}
