/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "../bootstrap-3-3-6/variables";
@import "../bootstrap-3-3-6/mixins";

// Reset and dependencies
@import "../bootstrap-3-3-6/normalize";
@import "../bootstrap-3-3-6/print";
@import "../bootstrap-3-3-6/glyphicons";

// Core CSS
@import "../bootstrap-3-3-6/scaffolding";
@import "../bootstrap-3-3-6/type";
@import "../bootstrap-3-3-6/code";
@import "../bootstrap-3-3-6/grid";
@import "../bootstrap-3-3-6/tables";
@import "../bootstrap-3-3-6/forms";
@import "../bootstrap-3-3-6/buttons";

// Components
@import "../bootstrap-3-3-6/component-animations";
@import "../bootstrap-3-3-6/dropdowns";
@import "../bootstrap-3-3-6/button-groups";
@import "../bootstrap-3-3-6/input-groups";
@import "../bootstrap-3-3-6/navs";
@import "../bootstrap-3-3-6/navbar";
@import "../bootstrap-3-3-6/breadcrumbs";
@import "../bootstrap-3-3-6/pagination";
@import "../bootstrap-3-3-6/pager";
@import "../bootstrap-3-3-6/labels";
@import "../bootstrap-3-3-6/badges";
@import "../bootstrap-3-3-6/jumbotron";
@import "../bootstrap-3-3-6/thumbnails";
@import "../bootstrap-3-3-6/alerts";
@import "../bootstrap-3-3-6/progress-bars";
@import "../bootstrap-3-3-6/media";
@import "../bootstrap-3-3-6/list-group";
@import "../bootstrap-3-3-6/panels";
@import "../bootstrap-3-3-6/responsive-embed";
@import "../bootstrap-3-3-6/wells";
@import "../bootstrap-3-3-6/close";

// Components w/ JavaScript
 @import "../bootstrap-3-3-6/modals";
 @import "../bootstrap-3-3-6/tooltip";
 @import "../bootstrap-3-3-6/popovers";
 @import "../bootstrap-3-3-6/carousel";

// Utility classes
@import "../bootstrap-3-3-6/utilities";
@import "../bootstrap-3-3-6/responsive-utilities";
