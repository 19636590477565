.footer-azul {
    font-family: ptsanscaption-regular;
    background-color: #00457d;
    height: auto;
    padding-top: 20px;
    position: relative;
    top: -6px;
    img {
        margin: 0;
        width: auto;
        float: none;
        display: inline-block;
        vertical-align: middle;
        font-size: 10px;
    }
    br {
        display: none;
    }
    .descarga-app img,
    .perfil-contratante .transparencia img,
    .perfil-contratante img {
        width: 20px;
        height: auto;
    }
    .descarga-app a,
    .perfil-contratante .transparencia a,
    .perfil-contratante a {
        display: block;
        height: 35px;
        margin-left: 10px;
        font-size: 0;
    }
}
.faq-footer {
    width: 100%;
    float: none;
    margin: 0;
    line-height: 0;
    a {
        margin-left: 50px;
    }
}
.texto-footer-azul {
    color: #FFF;
    font-weight: 700;
    width: auto;
    height: auto;
    text-transform: uppercase;
    line-height: 25px;
    margin: 0;
    float: none;
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
}
.acceso-rapido {
    padding: 25px 25px 25px 50px;
    width: 100%;
    ul {
        list-style: none;
        margin: 7px 0 0 5px;
        padding: 0;
        font-family: ptsanscaption-regular;
    }
    li {
        font-family: ptsanscaption-regular;
        margin: 10px 0 3px;
        list-style: none;
        font-size: 16px;
        &:first-child {
            border-top-color: #f10043;
        }
    }
    a,
    p {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
    }
}
.titulo-acceso-rapido {
    height: 25px;
    border-bottom: #b22e56 solid 2px;
    p {
        font-size: 13px;
        float: left;
        margin: -4px 0 0 5px;
        color: #FFF;
        text-transform: uppercase;
        font-weight: 700;
    }
}
.descarga-app .texto-footer-azul,
.perfil-contratante .texto-footer-azul,
.perfil-contratante .transparencia .texto-footer-azul {
    margin-left: 20px;
    line-height: 35px;
}
.sohoGothic-Bold {
    font-family: ptsanscaption-bold;
}
.transparencia img {
    margin-top: 10px;
}
@media (min-width:$screen-md) {
    .footer-azul > div {
        width: 25%;
        float: left;
    }
    .footer-azul {
        padding-top: 0;
        font-family: ptsanscaption-regular;
        background-color: #00457d;
        height: 320px;
        margin-top: 23px;
        br {
            display: block;
        }
        .faq-footer {
            float: left;
            margin: 58px 0 0 90px;
            width: 180px;
            line-height: 0;
            height: 0;
            a {
                margin: 0;
            }
            p {
                position: relative;
                left: 0;
                top: -15px;
            }
        }
        .descarga-app {
            float: left;
            display: block;
            width: 180px;
            position: relative;
            left: -10px;
            top: 0;
            margin-top: 46px;
            img {
                position: relative;
                top: 1px;
            }
            .texto-footer-azul {
                width: 140px;
                position: relative;
                left: 12px;
                top: -3px;
            }
        }
        .descarga-app img,
        .perfil-contratante .transparencia img,
        .perfil-contratante img {
            width: auto;
        }
        .perfil-contratante {
            float: left;
            margin-left: 26px;
            margin-top: 47px;
            width: 207px;
            position: relative;
            left: -10px;
            div:first-child {
                .texto-footer-azul {
                    left: 12px;
                    top: -3px;
                }
                img {
                    top: 0;
                }
            }
            img {
                position: relative;
                top: 2px;
            }
            .transparencia img {
                margin-top: 10px;
                width: 46px;
                height: 47px;
                position: relative;
                top: 1px;
                left: 2px;
            }
            .texto-footer-azul {
                position: relative;
                left: 14px;
                top: 8px;
            }
        }
        .acceso-rapido {
            float: left;
            margin: 67px 0 0 33px;
            width: 219px;
        }
        img {
            float: left;
        }
        .descarga-app .texto-footer-azul,
        .perfil-contratante .texto-footer-azul,
        .perfil-contratante .transparencia .texto-footer-azul {
            margin-left: 0;
        }
        .transparencia {
            position: relative;
            top: -4px;
            left: -2px;
        }
    }
    .texto-footer-azul {
        color: #FFF;
        float: left;
        font-size: 18px;
        font-weight: 700;
        height: auto;
        margin: 0 0 0 42px;
        text-transform: uppercase;
        width: 144px;
        line-height: 25px;
    }
    .footer-azul .perfil-contratante .transparencia .texto-footer-azul,
    .footer-azul .texto-footer-azul {
        line-height: 25px;
    }
    .acceso-rapido {
        float: left;
        margin: 47px 0 0 33px;
        width: 219px;
        padding: 0;
        div:first-child {
            height: 25px;
            border-bottom: #b22e56 solid 2px;
        }
        a,
        p {
            color: #fff;
            font-size: 14px;
            font-weight: normal;
        }
    }
    .footer-azul .acceso-rapido {
        margin-top: 67px;
    }
    .titulo-acceso-rapido {
        padding-bottom: 25px;
    }
    .titulo-acceso-rapido p {
        float: left;
        margin: -4px 0 0 5px;
        color: #FFF;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 25px;
        position: relative;
        top: -4px;
    }
}
