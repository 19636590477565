.alert p {
  line-height: 14px;
}

table.table {
  th {
    background-color: $blue;
    color: #FFF;

    a {
      color: #FFF;
    }
  }
}
@media (min-width: $screen-sm) {
  .form-inline.date-inline {
    .input-group {
      width: 73%;
    }
  }

  .form-inline {
    label {
      vertical-align: middle;
      width: 26%;
    }

    .form-control {
      width: 72%;
    }

    .form-group {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
@media (min-width: $screen-lg) {
  .form-inline.date-inline {
    .input-group {
      width: 69%;
    }
  }

  .form-inline {
    label {
      width: 30%;
    }

    .form-control {
      width: 68%;
    }
  }
}
